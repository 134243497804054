import { Box, Flex, Hide, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
import background from '../../assets/footer-bg.png';

export const Footer = () => {
  return (
    <Box
      bg={'white'}
      backgroundColor={'green.100'}
      px={0}
      borderBottom={0}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      height={['120px']}
      bottom={'0'}
      zIndex={'docked'}
      fontFamily={'agenda, sans-serif'}
      width={'100%'}>
      <Image height={['120px']} pos={'relative'} float={'right'} src={background} alt='Valt' />

      <Flex justifyContent={'space-evenly'} maxW={'1320px'} margin={'auto'}>
        <Text fontSize={{ base: 'xs' }} color={'white'} maxW={'50%'} marginTop={'10px'}>
          Copyright © 2024 Valt. All rights reserved. Valt® and the Valt Logo design are registered trademarks of Valt Real Estate Ltd. Other trademarks are the property of their respective owners.
        </Text>
        <Box color={'white'} maxW={'50%'} marginTop={'10px'}>
          <Flex gap={'5px'} flexDir={{ base: 'column', md: 'row' }}>
            <Link color={'gold.100'} href='https://www.valtup.com/general-terms-of-service/'>
              General Terms of Use
            </Link>
            <Hide below='md'>|</Hide>
            <Link color={'gold.100'} href='https://www.valtup.com/website-terms-of-use/'>
              Website Terms of Use
            </Link>
            <Hide below='md'>|</Hide>
            <Link color={'gold.100'} href='https://www.valtup.com/valt-privacy-policy/'>
              Privacy Policy
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
