import { AddressSelectorConfig, FormDefinition } from '@cksoftware/react-base';
import { PropertyDisplayType } from '../../../../enums/form/PropertyDisplayType.tsx';
import { generatePriceDropdown } from '../../../../util/generatePriceDropdown.ts';
import { HomeListingFilterViewMode } from '../../models/FilterViewMode.ts';
import { HomeListingFilterModel } from '../../models/HomeListingFilterModel.ts';

const filterAddressConfig: AddressSelectorConfig<HomeListingFilterModel> = {
  latField: 'Lat',
  lngField: 'Lng',
  types: ['(cities)'],
  addressSelectedField: 'HasSelectedAddress',
  searchStringField: 'SearchString'
};

export const MobileHomeListingFilterFormDefinition: FormDefinition<HomeListingFilterModel> = {
  questions: [
    {
      key: 'SearchString',
      placeHolder: 'Enter a city, neighbourhood, or postal code.',
      propertyDisplayType: PropertyDisplayType.AddressSelector,
      propertyDisplayTypeConfig: filterAddressConfig,
      variant: 'square',
      controlStyleProps: {
        w: { base: '80%', xl: '30%' },
        flexGrow: 1
      }
    },
    {
      key: 'MinPrice',
      placeHolder: 'Min Price',
      variant: 'square',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: generatePriceDropdown(0, 5000000, 50000),
        isNumeric: true
      },
      controlStyleProps: {
        maxW: { base: 'unset', xl: '150px' },
        w: { base: '50%', xl: 'unset' },
        flexGrow: 1
      },
      hideIf: {
        property: 'ViewMode',
        value: [HomeListingFilterViewMode.Normal.valueOf()]
      }
    },
    {
      key: 'MaxPrice',
      placeHolder: 'Max Price',
      variant: 'square',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: generatePriceDropdown(50000, 5000000, 50000),
        isNumeric: true
      },
      controlStyleProps: {
        maxW: { base: 'unset', lg: '150px' },
        w: { base: '50%', lg: 'unset' },
        flexGrow: 1
      },
      hideIf: {
        property: 'ViewMode',
        value: [HomeListingFilterViewMode.Normal.valueOf()]
      }
    }
  ],
  entityName: 'HomeSearchFilterForm',
  submitButtonText: ''
};
