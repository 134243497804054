import { Box, Button, Flex } from '@chakra-ui/react';
import { QuestionRenderer, useFormDefinitionForm } from '@cksoftware/react-base';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { JsonViewer } from '../../../../components/misc/JsonView';
import { useIsMobile } from '../../../../util/useIsMobile';
import { HomeListingFilterModel } from '../../models/HomeListingFilterModel';
import { HomeListingFilterDialog } from './HomeListingFilterDialog';
import { HomeListingFilterFormDefinition } from './HomeListingFilterFormDefinition';
import { MobileHomeListingFilterFormDefinition } from './MobileHomeListingFilterFormDefinition';

type HomeListingFilterFormProps = {
  filterModel: HomeListingFilterModel;
  onSearch: (model: HomeListingFilterModel) => void;
  showSearchButton: boolean;
  showFilterButton: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const HomeListingFilterForm = (props: HomeListingFilterFormProps) => {
  const isMobile = useIsMobile();
  const formDefinition = isMobile ? MobileHomeListingFilterFormDefinition : HomeListingFilterFormDefinition;
  const entityForm = useFormDefinitionForm(formDefinition, null, props.filterModel);

  useEffect(() => {
    const watcher = entityForm.methods.watch(() => {
      if (!props.showSearchButton) {
        props.onSearch(entityForm.methods.getValues());
      }
    });
    return () => watcher.unsubscribe();
  }, [entityForm.methods.watch, props.showSearchButton]);

  if (!props.filterModel || entityForm.query.isLoading) {
    return null;
  }

  return (
    <>
      <HomeListingFilterDialog
        filterModel={props.filterModel}
        onSearch={(model) => {
          props.onSearch(model);
          props.onClose();
        }}
        showSearchButton={true}
        isOpen={props.isOpen}
        onClose={props.onClose}
      />
      <FormProvider {...entityForm.methods}>
        {location.hostname === 'localhost' && (
          <Box pos={'fixed'} top={'40px'} left={'400px'} cursor={'pointer'} zIndex={'modal'}>
            <JsonViewer object={entityForm.methods.getValues()} />
          </Box>
        )}
        <form
          style={{ width: '100%' }}
          onSubmit={entityForm.methods.handleSubmit(
            async (model) => {
              props.onSearch(model);
            },
            (errors) => {
              console.log('error', errors);
            }
          )}>
          <Flex gap={0} w={'100%'} flexDir={{ base: 'row' }} rowGap={'5px'} justifyContent={'flex-end'}>
            {formDefinition.questions.map((value, index) => {
              return (
                <QuestionRenderer fieldPadding='4px' labelProps={{ margin: 0, padding: 0 }} key={index} formQuestionModel={value} entityPropertyModels={entityForm.query.data.FormPropertyModels} />
              );
            })}

            {props.showFilterButton && (
              <Button width={'75px'} onClick={props.onOpen}>
                <FontAwesomeIcon style={{ paddingRight: '5px' }} icon={faFilter} />
              </Button>
            )}

            {props.showSearchButton && (
              <Button w={'120px'} aria-label={'search'} type='submit'>
                Search
              </Button>
            )}
          </Flex>
        </form>
      </FormProvider>
    </>
  );
};
