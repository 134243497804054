import { Box, Flex, Progress, useDisclosure } from '@chakra-ui/react';
import { useHoverState } from '@cksoftware/react-base';
import { useCallback, useMemo, useRef, useState } from 'react';
import { MapBoundingBox } from '../../../globalModels/common/mapBoundingBox';
import { MapLocation } from '../../../globalModels/common/mapLocation';
import { calculateDistanceOfBounds } from '../../../util/calculateDistanceOfBounds';
import { useHomeListingQuery } from '../api/useHomeListingQuery';
import { HomeListingFilterModel } from '../models/HomeListingFilterModel';
import { HomeListingResponseModel } from '../models/HomeListingResponseModel';
import { HomeListingFilterForm } from './HomeListingFilters/HomeListingFilterForm';
import { HomeListingItem } from './HomeListingItem/HomeListingItem';
import { HomeListingMap } from './HomeListingMap/HomeListingMap';

type HomeListingContainerProps = {
  filterModel: HomeListingFilterModel;
  setFilterModel: (model: HomeListingFilterModel) => void;
  queryKey: string;
};

export const HomeListingContainer = ({ filterModel, setFilterModel, queryKey }: HomeListingContainerProps) => {
  const homeListings = useHomeListingQuery(filterModel, queryKey);

  const [hoverItem, onHoverEnd, onHoverStart] = useHoverState<HomeListingResponseModel>(undefined);
  const [mapMarkerHoverItem, , onMapMarkerHoverStart] = useHoverState<HomeListingResponseModel>(undefined);
  const [mapBounds, setMapBounds] = useState<MapBoundingBox>();
  const filterDisclosure = useDisclosure();

  const onMapMove = useCallback(
    (boundingBox: MapBoundingBox, mapCenter: MapLocation) => {
      const distance = calculateDistanceOfBounds(boundingBox.TopRight.Lat, boundingBox.TopRight.Lng, boundingBox.BottomLeft.Lat, boundingBox.BottomLeft.Lng);
      setMapBounds(boundingBox);
      const newFilter = { ...filterModel };
      newFilter.RangeKm = Math.round(distance);
      newFilter.Lat = mapCenter.Lat;
      newFilter.Lng = mapCenter.Lng;
      setFilterModel(newFilter);
    },
    [filterModel, setFilterModel, mapBounds, homeListings.refetch]
  );

  const scrollRef = useRef<HTMLDivElement>();

  const filteredHomeData = useMemo(() => {
    if (!homeListings.data) {
      return [];
    }
    return homeListings.data.filter((value) => {
      if (!mapBounds) {
        return true;
      }
      return (
        value.PropertyListingResponse.Latitude <= mapBounds.TopRight.Lat &&
        value.PropertyListingResponse.Latitude >= mapBounds.BottomLeft.Lat &&
        value.PropertyListingResponse.Longitude <= mapBounds.TopRight.Lng &&
        value.PropertyListingResponse.Longitude >= mapBounds.BottomLeft.Lng
      );
    });
  }, [homeListings.data, mapBounds]);

  return (
    <Box overflow={'hidden'} h={{ base: 'calc(100vh - 60px)', sm: 'calc(100vh - 120px)' }}>
      <Box w={'100%'} margin={'auto'}>
        <HomeListingFilterForm
          filterModel={filterModel}
          onSearch={(model) => {
            setFilterModel(model);
          }}
          showSearchButton={false}
          showFilterButton={true}
          onClose={filterDisclosure.onClose}
          onOpen={filterDisclosure.onOpen}
          isOpen={filterDisclosure.isOpen}
        />
      </Box>
      <Flex gap={'0'} h={{ base: 'calc(100vh - 120px)', sm: 'calc(100vh - 165px)' }}>
        <Box w={{ base: '100%', xl: '70%', '2xl': '50%' }}>
          {homeListings.isFetching && <Progress size='xs' isIndeterminate />}
          <Flex flexDir={'column'} h={'100%'} gap={'10px'} overflowY={'auto'}>
            {filteredHomeData.map((listing, index) => {
              return (
                <div ref={scrollRef} key={index}>
                  <HomeListingItem
                    isShowingFullPrice={filterModel.ShowPrice}
                    onHoverStart={onHoverStart}
                    onHoverEnd={onHoverEnd}
                    isHoverItem={mapMarkerHoverItem == listing}
                    key={index}
                    model={listing}
                  />
                </div>
              );
            })}
            {filteredHomeData.length == 0 && !homeListings.isFetching && <Box>Sorry, no results found. Try modifying your search parameters or moving the map.</Box>}
          </Flex>
        </Box>
        <Box h={'100%'} w={'100%'}>
          <HomeListingMap data={homeListings.data ?? []} hoverItem={hoverItem} onHoverMapMarker={onMapMarkerHoverStart} onMapMove={onMapMove} center={{ lat: filterModel.Lat, lng: filterModel.Lng }} />
        </Box>
      </Flex>
    </Box>
  );
};
