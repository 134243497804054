import { Box, Flex } from '@chakra-ui/react';
import { formatCurrency } from '@cksoftware/react-base';
import { useMemo } from 'react';
import { DisplayProperty } from '../models/DisplayProperty';
import { HomeDetailsResponseModel } from '../models/HomeDetailsResponseModel';
import { getHomeSizeSquareFootage } from '../util/getHomeSizeSquareFootage';
import { PropertyInfoSection } from './PropertyInfoSection';

type PropertyDetailsProps = {
  model: HomeDetailsResponseModel;
};

export const PropertyDetails = (props: PropertyDetailsProps) => {
  const propertyDetails = useMemo<DisplayProperty[]>(() => {
    return [
      { label: 'MLS® #', value: props.model.PropertyListingResponse.ListingId },
      { label: 'Price', value: formatCurrency(props.model.PropertyListingResponse.ListPrice) },

      { label: 'Bedrooms', value: props.model.PropertyListingResponse.BedroomsTotal },
      { label: 'Bathrooms', value: props.model.PropertyListingResponse.BathroomsTotalInteger },
      { label: 'Square Footage', value: getHomeSizeSquareFootage(props.model.PropertyListingResponse) },
      { label: 'Listing Age', value: props.model.DaysListed != undefined ? `${props.model.DaysListed} days` : undefined },
      { label: 'Storeys', value: props.model.PropertyListingResponse.Stories },
      {
        label: 'Land Size',
        value: props.model.PropertyListingResponse.LotSizeArea && !isNaN(props.model.PropertyListingResponse.LotSizeArea) ? Math.round(props.model.PropertyListingResponse.LotSizeArea) : undefined,
        secondaryValue: props.model.PropertyListingResponse.LotSizeUnits
      },
      {
        label: 'Year Built',
        value: props.model.PropertyListingResponse.YearBuilt
      },
      { label: 'Property Type', value: props.model.PropertyListingResponse.PropertySubType },

      { label: 'Built In', value: props.model.PropertyListingResponse.YearBuilt },
      { label: 'Property Tax', value: props.model.PropertyListingResponse.TaxAnnualAmount ? formatCurrency(props.model.PropertyListingResponse.TaxAnnualAmount) : '' }
    ];
  }, []);

  const communityDetails = useMemo<DisplayProperty[]>(() => {
    return [
      { label: 'Address', value: props.model.PropertyListingResponse.UnparsedAddress },
      { label: 'Area', value: props.model.PropertyListingResponse.CityRegion },
      { label: 'Community Name', value: props.model.PropertyListingResponse.CityRegion },
      { label: 'Subdivision Name', value: props.model.PropertyListingResponse.SubdivisionName },
      { label: 'Province', value: props.model.PropertyListingResponse.StateOrProvince },
      { label: 'Postal Code', value: props.model.PropertyListingResponse.PostalCode }
    ];
  }, []);

  const homeFeatures = useMemo<DisplayProperty[]>(() => {
    return [
      { label: 'Appliances', valueList: [...props.model.PropertyListingResponse.Appliances] },
      { label: 'Basement Type', valueList: [...props.model.PropertyListingResponse.Basement] },
      { label: 'Heating/Cooling', valueList: [...props.model.PropertyListingResponse.Heating, ...props.model.PropertyListingResponse.Cooling] },
      { label: 'Parking', valueList: [...props.model.PropertyListingResponse.ParkingFeatures] },
      { label: 'Fireplace', valueList: [...props.model.PropertyListingResponse.FireplaceFeatures] },
      { label: 'Lot Features', valueList: [...props.model.PropertyListingResponse.LotFeatures] },
      { label: 'Community Features', valueList: [...props.model.PropertyListingResponse.CommunityFeatures] },
      { label: 'Building Type', value: props.model.PropertyListingResponse.ArchitecturalStyle ? props.model.PropertyListingResponse.ArchitecturalStyle[0] : '' }
    ];
  }, []);

  return (
    <Box>
      <Flex flexDir={'column'} gap={'10px'}>
        <PropertyInfoSection propertiesToDisplay={propertyDetails} header={'Property Details'} />
        <PropertyInfoSection propertiesToDisplay={communityDetails} header={'Community Details'} />
        <PropertyInfoSection propertiesToDisplay={homeFeatures} header={'Home Features'} />
      </Flex>
    </Box>
  );
};
