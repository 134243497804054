import { HomeSortBy } from '../../../enums/form/HomeSortBy';
import { SpecificOrMinFilterAmount } from '../../../enums/form/SpecificOrMinFilterAmount';
import { HomeListingFilterViewMode } from './FilterViewMode.ts';

export type HomeListingFilterModel = {
  Lat: number | undefined;
  Lng: number | undefined;
  MinPrice: number | undefined;
  MaxPrice: number | undefined;
  Bedrooms: SpecificOrMinFilterAmount | undefined;
  Bathrooms: SpecificOrMinFilterAmount | undefined;
  RangeKm: number | undefined;
  SearchString: string;
  SquareFootageMin: number | undefined;
  SquareFootageMax: number | undefined;
  ShowPrice: boolean;
  SortBy: HomeSortBy;
  ListingAge: number | undefined;
  ViewMode: HomeListingFilterViewMode | undefined;
  HasSelectedAddress: boolean;
  SelectedAddress: string | undefined;
  Storeys: SpecificOrMinFilterAmount | undefined;
  LotSizeMinimum: number | undefined;
  LotSizeMaximum: number | undefined;
  CommonInterest: string | undefined;
};

export const DefaultHomeListingFilterModel: HomeListingFilterModel = {
  Lat: undefined,
  Lng: undefined,
  MinPrice: undefined,
  MaxPrice: undefined,
  Bathrooms: undefined,
  Bedrooms: undefined,
  RangeKm: 20,
  SearchString: undefined,
  SquareFootageMin: undefined,
  SquareFootageMax: undefined,
  ShowPrice: false,
  SortBy: HomeSortBy.Newest,
  ListingAge: undefined,
  ViewMode: HomeListingFilterViewMode.Normal,
  HasSelectedAddress: undefined,
  Storeys: undefined,
  LotSizeMinimum: undefined,
  LotSizeMaximum: undefined,
  CommonInterest: undefined,
  SelectedAddress: undefined
};
